<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!importation">
      <span class="loader"></span>
    </span>
    <div v-if="importation" class="elite-tabs-wrapper">
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        :value="currentTab"
        @onTabChanged="tabChanged"
      >
        <tab-pane title="global" id="1" :active="true">
          <span slot="title">
            <i class="ni ni-cloud-upload-96"></i>
            {{ $t("COMMON.GLOBAL") }}
          </span>
          <import-view-global :importation="importation" />
        </tab-pane>

        <tab-pane
          title="logs"
          id="999"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOGS)"
        >
          <span slot="title">
            <i class="fa fa-file"></i>
            {{ $t("COMMON.LOGS") }}
          </span>
          <import-view-logs :importation="importation" />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Link,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { TabPane, Tabs } from "@/components";
import { STATUS_COMPLETED, STATUS_DRAFT } from "@/constants/imports";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ImportViewGlobal from "../partials/ImportViewGlobal.vue";
import ImportViewLogs from "../partials/ImportViewLogs.vue";
import ImportViewResults from "../partials/ImportViewResults.vue";
import ImportResults from "../partials/ImportResults.vue";
import ImportViewImportedItems from "../partials/ImportViewImportedItems.vue";
import defaultImport from "../defaultImport";
import hasTabs from "@/mixins/has-tabs";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Link.name]: Link,
    Tabs,
    TabPane,
    ImportViewGlobal,
    ImportViewLogs,
  },

  mixins: [requestErrorMixin, hasTabs],

  data() {
    return {
      importation: cloneDeep(defaultImport),
      id: this.$route.params.id,
      showResultsModal: false,
      importResults: [],
      STATUS_DRAFT: STATUS_DRAFT,
      STATUS_COMPLETED: STATUS_COMPLETED,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("imports/get", this.id);
        this.importation = this.$store.getters["imports/import"];
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async runDry() {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.RUN_DRY_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (confirmation.isConfirmed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const importation = await this.$store.dispatch(
          "imports/runDry",
          this.id
        );
        this.importResults = importation.results;
        this.get();
        swal.close();
        this.showResultsModal = true;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async run() {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.RUN_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });
      if (confirmation.isConfirmed) {
        return;
      }

      swal.fire({
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          swal.showLoading();
        },
      });

      try {
        const importation = await this.$store.dispatch("imports/run", this.id);
        this.importResults = importation.results;
        this.get();
        swal.close();
        this.showResultsModal = true;
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async deleteImport() {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.DELETE_THIS_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("imports/destroy", this.id);
          this.goBack();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("IMPORTS.IMPORT_DELETED"),
          });
        }
      } catch (error) {
        this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    goBack() {
      this.$router.push({ name: "List Imports" });
    },
  },

  computed: {},
};
</script>
