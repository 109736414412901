<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-regular fa-cube"></i>
      <h1>{{ $t("COMMON.IMPORTS") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("IMPORTS.IMPORTS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openImportCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_IMPORTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("IMPORTS.ADD_IMPORT")
              }}</span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_IMPORTS)"
              :objectType="'imports'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <import-list-table
        @onViewImport="openImportViewModal"
        @onEditImport="openImportEditModal"
        @onDeleteImport="deleteImport"
        :key="renderKey * 100"
      />
      <div
        v-if="isViewImportModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewImportModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'IMPORT'"
          @onCloseModal="closeImportModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("IMPORTS.VIEW_IMPORT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openImport"
                :objectType="'imports'"
                :objectId="openImport.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <button class="close" @click="closeImportModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-import-page v-if="openImport" :importId="openImport.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditImportModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditImportModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'IMPORT'"
          @onCloseModal="closeImportModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("IMPORTS.EDIT_IMPORT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="openImportViewModal(openImport)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-import-page
              v-if="openImport"
              :importId="openImport.id"
              @onViewImport="openImportViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddImportModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddImportModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'IMPORT'"
          @onCloseModal="closeImportModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("IMPORTS.ADD_IMPORT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeImportModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-import-page @onViewImport="openImportViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ImportListTable from "./partials/ImportListTable.vue";
import EditImportPage from "./components/EditImportComponent.vue";
import AddImportPage from "./components/AddImportComponent.vue";
import ViewImportPage from "./components/ViewImportComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ImportListTable,
    NotificationSubscription,
    EditImportPage,
    AddImportPage,
    ViewImportPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const importId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewImportModalOpened = false;
    let isEditImportModalOpened = false;
    let isAddImportModalOpened = false;
    let openImport = null;
    if (importId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewImportModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditImportModalOpened = true;
      }
      openImport = { id: importId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddImportModalOpened = true;
    }
    return {
      isViewImportModalOpened: isViewImportModalOpened,
      isEditImportModalOpened: isEditImportModalOpened,
      isAddImportModalOpened: isAddImportModalOpened,
      openImport: openImport,
      renderKey: 1,
    };
  },

  methods: {
    openImportCreateModal() {
      this.closeImportModal();
      this.isAddImportModalOpened = true;

      this.$router.replace({
        name: "List Imports",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openImportViewModal(importName, reRender = false) {
      this.closeImportModal();
      this.openImport = importName;
      this.isViewImportModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Imports",
        query: { id: this.openImport.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openImportEditModal(importation) {
      this.closeImportModal();
      this.openImport = importation;
      this.isEditImportModalOpened = true;

      this.$router.replace({
        name: "List Imports",
        query: { id: this.openImport.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    closeImportModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddImportModalOpened = false;
      this.isViewImportModalOpened = false;
      this.isEditImportModalOpened = false;
      this.openImport = null;

      if (
        this.$route.name !== "List Imports" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Imports",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteImport(importation) {
      const confirmation = await swal.fire({
        title: this.$t("IMPORTS.DELETE_THIS_IMPORT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("imports/destroy", importation.id);
          this.renderKey++;
          this.closeImportModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("IMPORTS.IMPORT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
